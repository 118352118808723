// THREE.JS - CODE SHEET

//---------------------------------------------------------------------------LIBRARYS

//creamos el namespace TRHEE
import * as THREE from "three";

//importamos el modulo de orbit control
import { OrbitControls } from "three/examples/jsm/Addons.js";

//importamos la clase dat.gui
import * as dat from 'dat.gui';

//importamos las imagenes de la carpeta
import nebula from '../img/nebula.jpg'
import stars from '../img/stars.jpg'

//importarmos el importador de modelos 3D
import { GLTFLoader } from "three/examples/jsm/Addons.js";

//-----------------------------------------------------------------------------RENDER

//creamos una instancia del renderizador
const renderer = new THREE.WebGLRenderer();
//modificamos el tamaño del espacio de trabajo
renderer.setSize(window.innerWidth, window.innerHeight);

//agregamos el elemento canvas para incorporarlo a la pagina
document.body.appendChild(renderer.domElement);

//------------------------------------------------------------------------------SCENE

//creamos la escena
const scene = new THREE.Scene();

//creamos la camara
const camera = new THREE.PerspectiveCamera(
    75,
    window.innerWidth / window.innerHeight,
    0.1,
    1000
);

//agregamos un sistemas de coordenadas AxesHelper(longitud del eje)
const axesHelper = new THREE.AxesHelper(2);
//agregamos el sistema a la escena
scene.add(axesHelper);

//-------------------------------------------------------------------ORBIT_CONTROLLER

//creamos una nueva instancia de orbit control
const orbit = new OrbitControls(camera, renderer.domElement);

//modificamos la posicion de la camera
camera.position.set(0, 2, 5);
//llamamos al metodo de actualizacion cada vez que cambiemos la camara
orbit.update();

//--------------------------------------------------------------------------GEOMETRYS

//CUBO
//creamos la geometria de la figura
//BoxGeometry(tamaño)
const boxGeometry = new THREE.BoxGeometry();
//material que aplicaremos a la geometria
const boxMaterial = new THREE.MeshStandardMaterial({ 
    //añadimos color
    color: 0xff4455,
});
//creamos la malla usando la geomtria y el material previos
const box = new THREE.Mesh(boxGeometry, boxMaterial);
//agregamos la malla a la escena
scene.add(box);

//PLANO
//PlaneGeometry(tamaño)
const planeGeometry = new THREE.PlaneGeometry(20, 20);
const planeMaterial = new THREE.MeshStandardMaterial({
    color: 0xFFFFFF,
    //indicamos que sea reversible
    side: THREE.DoubleSide
});
const plane = new THREE.Mesh(planeGeometry, planeMaterial);
scene.add(plane);

//rotamos el plano y movemos el plano
plane.rotation.x = -0.5 * Math.PI;
plane.position.y = -1.0;

//ESFERA
//SphereGeometry(radio, ancho de los segmentos, alto de los segmentos)
const sphereGeometry = new THREE.SphereGeometry(2, 20, 20);
const sphereMaterial = new THREE.MeshStandardMaterial({
    color: 0x00AADD,
    //activamos el modo wireframe
    wireframe: false
});
const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
scene.add(sphere);

sphere.position.set(-6, 3, 0);

//-------------------------------------------------------------------------------GRID

//creamos un ayudante de cuadricula(tamaño de la superficie, candidad de cuadrados)
const gridHelper = new THREE.GridHelper(20, 20);
scene.add(gridHelper);

gridHelper.position.y = -1.0;

//-----------------------------------------------------------------------------LIGHTS

//creamos una luz ambiental(color)
const ambientLight = new THREE.AmbientLight(0x333333);
scene.add(ambientLight);

//creamos una luz direccional(color, intensidad)
const directionalLight = new THREE.DirectionalLight(0xFFFFFF, 1.8);
scene.add(directionalLight);

//cambiamos la ubiacion de la luz
directionalLight.position.set(-30, 50, 0);

//aumentamos el tamaño inferior de la camara de sombras
directionalLight.shadow.camera.bottom = -12;

//ayudante visual de la direccion de las luces(luz, tamaño)
const dLightHelper = new THREE.DirectionalLightHelper(directionalLight, 5)
scene.add(dLightHelper);

//creamos una spotlight
const spotLight = new THREE.SpotLight(0xFFFFFF);
scene.add(spotLight);

//cambiamos la posicion de la luz
//spotLight.position.set(-100, 100, 0);
//activamos la emision de sombras
spotLight.castShadow = true;
//corregimos el angulo para corregir el pixeleado
spotLight.angle = 0.2;

//creamos un ayudante visual para la spotLight
const sLightHelper = new THREE.SpotLightHelper(spotLight);
scene.add(sLightHelper);

//----------------------------------------------------------------------------SHADOWS

//activamos el mapa de sombras
renderer.shadowMap.enabled = true;

//activamos la recepcion de sombra en la malla del plano
plane.receiveShadow = true;

//activamos la emision de sombra en las mallas
sphere.castShadow = true;
box.castShadow = true;

//activamos la emision de sombra en la luz
directionalLight.castShadow = true;

//creamos un ayudante visual del area de visualizacion de las sombras
const dLightShadowHelper = new THREE.CameraHelper(directionalLight.shadow.camera);
scene.add(dLightShadowHelper);

//--------------------------------------------------------------TEXTURES_&_BACKGROUND

//cambiar el color de fondo
//renderer.setClearColor(0xFFEA00);

//cargamos una textura
const textureLoader = new THREE.TextureLoader();
//establecemos la imagen como fondo
//scene.background = textureLoader.load(stars);

//cargador de texturas 6D (skybox)
const cubeTextureLoader = new THREE.CubeTextureLoader();
//cargamos la textura y establecemos los 6 lados
scene.background = cubeTextureLoader.load([
    nebula,
    nebula,
    stars,
    stars,
    stars,
    stars
]);

//--------------------------------------------------------------------------MATERIALS

//Material normal
const torusGeometry = new THREE.TorusGeometry();
//map para agregar textura, el color se aplica a la textura
const torusMaterial = new THREE.MeshStandardMaterial({
    //color: 0x55FF66,
    map: textureLoader.load(nebula)
});
const torus = new THREE.Mesh(torusGeometry, torusMaterial);
scene.add(torus);
torus.castShadow = true;

torus.position.set(4, 1, 0);

//podemos cambiar el materiala accediento al atributo
torus.map = textureLoader.load(nebula);

//Material de 6 caras
const cubeGeometry = new THREE.BoxGeometry(2, 2, 2);
//creamo un array con los 6 materiales
const cubeMultiMaterial = [
    new THREE.MeshBasicMaterial({map: textureLoader.load(stars)}),
    new THREE.MeshBasicMaterial({map: textureLoader.load(stars)}),
    new THREE.MeshBasicMaterial({map: textureLoader.load(stars)}),
    new THREE.MeshBasicMaterial({map: textureLoader.load(stars)}),
    new THREE.MeshBasicMaterial({map: textureLoader.load(nebula)}),
    new THREE.MeshBasicMaterial({map: textureLoader.load(nebula)})
];
//añadimos el multi material
const cube = new THREE.Mesh(cubeGeometry, cubeMultiMaterial);
scene.add(cube);

cube.castShadow = true;
cube.position.set(0, 1, 6);
console.log(cube.name);

//--------------------------------------------------------------------------RAYCASTER

//creamos un vector para almacenar la posicion del mouse
const mousePosition = new THREE.Vector2();

//creamos el evento para obtener la posicion del mouse
window.addEventListener('mousemove', e => {
    //convertimos el valor normalizado de las coordenadas
    mousePosition.x = (e.clientX / window.innerWidth) * 2 - 1;
    mousePosition.y = - (e.clientY / window.innerHeight) * 2 + 1;
});

//creamos el rayo
const raycaster = new THREE.Raycaster();

//almacenamos el identificador de un objeto
const sphereID = sphere.id;

//creamos un nombre para identificar al cubo y a la dona
cube.name = 'cube6D';
torus.name = `torus`;

//-------------------------------------------------------------------------------MESH

//podemos cambiar la forma de una
const meshChangeGeometry = new THREE.PlaneGeometry(10, 10, 10, 10);
const meshChangeMaterial = new THREE.MeshBasicMaterial({
    color: 0x0000FF,
    wireframe: true
});
const meshChange = new THREE.Mesh(meshChangeGeometry, meshChangeMaterial);
scene.add(meshChange);

meshChange.position.set(10, 10, 15);

meshChange.geometry.attributes.position.array[0] -= 10 * Math.random();
meshChange.geometry.attributes.position.array[1] -= 10 * Math.random();
meshChange.geometry.attributes.position.array[2] -= 10 * Math.random();

const lastPointZ = meshChange.geometry.attributes.position.array.length - 1;

meshChange.geometry.attributes.position.array[lastPointZ] -= 10 * Math.random();

//----------------------------------------------------------------------------SHADERS

//creamos una variable de tipo vertexShader
const vShader = `
void main() {
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}
`;

//creamos una variable de tipo fragmentShader
const fShader = `
    void main() {
        gl_FragColor = vec4(0.5, 0.5, 1.0, 1.0);
    }
`;

const shaderGeometry = new THREE.SphereGeometry(0.5);
//agregamos los atributos del shader
const shaderMaterial = new THREE.ShaderMaterial({
    vertexShader: vShader,
    fragmentShader: fShader
});
const shader = new THREE.Mesh(shaderGeometry, shaderMaterial);
scene.add(shader);

shader.castShadow = true;
shader.position.set(0, 1, -6)

//----------------------------------------------------------------------IMPORT_MODELS

//debemos usar el formato correcto gltf. fbx u obj
//importamos el modelo 3d
const model3D = new URL('../assets/monkey.glb', import.meta.url);

//creamos una instancia del cargador de modelos 3d
const modelLoader = new GLTFLoader();

//cargamos el modelo a la escena usandolo como propiedad
modelLoader.load(model3D.href, gltf => {
    const model = gltf.scene;
    scene.add(model);

    model.position.set(0, 4, 0);
}, undefined, error => error);

//---------------------------------------------------------------------------RESPONSIVE

window.addEventListener('resize', function() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    
    renderer.setSize(window.innerWidth, window.innerHeight);
});

//----------------------------------------------------------------------------DAT.GUI

//creamos una instancia de la clase dat.gui
const gui = new dat.GUI();

//creamos un elemento a manera de modificador de parametros
const options = {
    sphereColor: '#00FF00',
    wireframe: true,
    speed: 0.01,
    size: 0.5,
    angle: 0.5,
    penumbra: 0.0,
    sLightColor: '#FFFFFF',
    intensity: 1.0
};

//creamos un forlder para los parametros
const mainFolder = gui.addFolder('Main');
//abrimo el folder para añadir los parametros
mainFolder.open();

const sphereFolder = mainFolder.addFolder('Sphere');

//añadimos la opcion de cambio de color al GUI
sphereFolder.addColor(options, 'sphereColor').onChange(e => {
    sphere.material.color.set(e);
}).name('Color');

//añadimos la opcion de activar la malla al GUI
sphereFolder.add(options, 'wireframe').onChange(e => {
    sphere.material.wireframe = e;
}).name('Wireframe');

//creamos un slider con el valor de la velocidad(valor minimo, valor maximo)
sphereFolder.add(options, 'speed', 0, 0.1).name('Speed');

//controlador del tamaño de la esfera
sphereFolder.add(options, 'size', 0.5, 1).name('Size');

//creamos un folder para los parametros
const sLightFolder = mainFolder.addFolder('SpotLight');

//añadimos un control de cambio de angulo, controla el tamaño del spot
sLightFolder.add(options, 'angle', 0, 1).name('Angle');

//añadimos un control de penumbra, agrega desenfoque al spot
sLightFolder.add(options, 'penumbra', 0, 1).name('Penumbra');

//añadimos un control de intensidad de la luz
sLightFolder.add(options, 'intensity', 0, 1).name('Intensity');

//añadimos un parametro de para mover la spotlight
sLightFolder.add(spotLight.position, 'x', -4, 4).name('Axis X');
sLightFolder.add(spotLight.position, 'y',  1, 4).name('Axis Y');
sLightFolder.add(spotLight.position, 'z', -4, 4).name('Axis Z');

sLightFolder.addColor(options, 'sLightColor').onChange(e => {
    spotLight.color.set(e);
}).name('Color');

//abrimo el folder para añadir los parametros
sLightFolder.open();

//--------------------------------------------------------------------------------FOG

//creamos niebla(color, near, far)
//scene.fog = new THREE.Fog('#FF00AA', 0, 100);

//segundo metodo con crecimiento exponencial de la niebla(color, densidad)
scene.fog = new THREE.FogExp2(0xFFFFFF, 0.05);

//--------------------------------------------------------------------------ANIMATION

//pasos y velocidad de la animacion
let step = 0;

//creamos una función de animación(velocidad de la animación)
function animate(time) {
    //------------------------------------------------------------TRANSFORM
    
    //accedemos a la transformacon del objeto
    box.rotation.set(time/3000, time/3000, time/3000);
    
    //modificamos la escala de la esfera
    sphere.scale.set(options.size, options.size, options.size);
    
    //creamos una animacion de rebote
    step += options.speed;
    sphere.position.y = 1 * Math.abs(Math.sin(step) + 2);
    
    //------------------------------------------------------------SPOTLIGHT
    
    //agregamos lo modificadores al GUI
    spotLight.angle = options.angle;
    spotLight.penumbra = options.penumbra;
    spotLight.intensity = options.intensity;
    
    //siempre que hay un cambio en la luz tenemos que actualizar el helper
    sLightHelper.update();

    //------------------------------------------------------------RAYCASTER
    
    //configuramos el inicio y el final del raycaster
    raycaster.setFromCamera(mousePosition, camera);
    
    //retorna el objeto contra el que choco con el rayo
    const intersects = raycaster.intersectObjects(scene.children);
    console.log(intersects);

    //three.js da automaticamente un identificador a cada objeto
    //recorremos los diferentes objetos obtenidos que va obteniendo el rallo
    for (let i = 0; i < intersects.length; i++) {
        //si el objeto que toca el rayo tiene el id, cambiara el color
        if (intersects[i].object.id === sphereID){
            intersects[i].object.material.color.set(0xFF0000);        
        }
        if (intersects[i].object.name === "cube6D"){
            intersects[i].object.rotation.set(time/3000, time/3000, time/3000);      
        }
        if (intersects[i].object.name === "torus"){
            intersects[i].object.scale.set(.5, .5, .5);      
        } else intersects[i].object.scale.set(1, 1, 1); 
    }

    //-----------------------------------------------------------------MESH

    meshChange.geometry.attributes.position.array[0] = 10 * Math.random();
    meshChange.geometry.attributes.position.array[1] = 10 * Math.random();
    meshChange.geometry.attributes.position.array[2] = 10 * Math.random();
    meshChange.geometry.attributes.position.array[lastPointZ] = 10 * Math.random();
    meshChange.geometry.attributes.position.needsUpdate = true;

    //---------------------------------------------------------------RENDER

    //vinculamos la escena con la camara
    renderer.render(scene, camera);
}

//generamos el loop de rendizado
renderer.setAnimationLoop(animate);

//--------------------------------------------------------------------------------end